import { ENUM_PRODUCT_NAMES } from "@prisma/client";
import { SignUp, SignupModal } from "@roadflex/react-components-www";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import { useEffect, useState } from "react";
import BigBanner from "../common-components/big-banner/big-banner";
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function Signup() {
  const { isMobile } = usePlatformDetect();
  const [showSignupModal, setshowSignupModal] = useState(false);
  useEffect(() => {
    if (isMobile && isMobile()) {
      setshowSignupModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile()]);

  return (
    <div
      className={classNames(
        isMobile() ? "flex flex-col" : "flex flex-row  h-[calc(100vh-64px)]",
        "w-full",
      )}
    >
      <BigBanner
        heading="Start saving fuel with RoadFlex"
        details="No Personal Guarantee needed"
        vertical={showSignupModal ? false : true}
      ></BigBanner>
      <div id="signup" className="flex flex-col justify-center w-full">
        <div className="container items-center hidden md:flex md:flex-col">
          <SignUp productName={ENUM_PRODUCT_NAMES.FUELCARD} />
        </div>
        {showSignupModal && (
          <SignupModal
            productName={ENUM_PRODUCT_NAMES.FUELCARD}
            showSignupModal={showSignupModal}
            setshowSignupModal={setshowSignupModal}
          ></SignupModal>
        )}
        <div className="flex flex-col items-center justify-center my-4 md:hidden">
          <div className="my-2 text-lg text-center">
            Click on &quot;Start Application&quot;
          </div>
          <div className="relative w-64 h-128 min-w-[245px] min-h-[320px] lg:min-w-[340px] lg:min-h-[450px] mt-8">
            <Image
              className="drop-shadow-xl"
              src={`${NEXT_PUBLIC_CDN_URL}/images/card-fuel-pump.png`}
              alt="RoadFlex sign up"
              layout="fill"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
