/** @format */
import { HeadSeo } from "@roadflex/react-components-www";
import Signup from "../../components/signup";

export function Index() {
  const keywordList: string[] = ["RoadFlex"];
  return (
    <>
      <HeadSeo
        url="${NEXT_PUBLIC_WWW_URL}/signup"
        title="RoadFlex - Apply to save more on fuel"
        description="Start saving more on fuel. Competitive discounts. Best-in-class technology platform built for you. Approved in 1 day. Apply now!"
        keywords={keywordList}
      />
      <Signup />
    </>
  );
}

export default Index;
