/** @format */

import {
  CreditCardIcon,
  GlobeAltIcon,
  LockClosedIcon,
  MapPinIcon,
  PhoneArrowDownLeftIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import { useRouter } from "next/router";

interface BigBannerProps {
  heading: string;
  details: string;
  heading2?: string;
  includeCallToAction?: boolean;
  vertical?: boolean;
  contactUs?: boolean;
}
export function BigBanner(props: BigBannerProps) {
  const {
    heading,
    details,
    heading2,
    includeCallToAction = false,
    vertical = false,
    contactUs = false,
  } = props;
  const router = useRouter();
  const goToSignupPage = () => {
    router.push({
      pathname: "/signup",
    });
  };
  return (
    <div
      className={`${
        vertical
          ? `block w-full h-full text-center text-white py-14 md:py-28 bg-cover bg-banner-header`
          : `block w-full h-full text-center text-white py-14 md:py-28 bg-cover bg-banner-header`
      }`}
    >
      <div
        className={`${
          vertical
            ? `flex flex-col justify-center text-center animate-slidein h-full`
            : `container md:my-24 min-h-fit animate-slidein`
        }`}
      >
        <div className="mb-3 text-2xl font-semibold md:text-4xl">
          <h1 className="px-4 lg:px-0">{heading}</h1>
          {heading2 && <div className="">{heading2}</div>}
        </div>
        <div className="px-12 lg:px-20 py-3 text-base md:text-xl">
          {details}
        </div>
        <div className="flex justify-center mt-4">
          {includeCallToAction && (
            <Button
              variant={ButtonVariant.Orange}
              onClick={goToSignupPage}
              className="!px-4 sm:!px-8 font-medium"
              size={ButtonSize.Large}
            >
              Apply Now
            </Button>
          )}
        </div>
        {contactUs && (
          <div className="flex justify-center px-4 mt-12">
            <div className="">
              <div className="flex flex-col space-y-4 text-sm md:text-base 3xl:text-lg">
                <div className="flex items-center">
                  <ReceiptPercentIcon
                    className="flex-shrink-0 inline-block w-8 h-8 stroke-orange-500"
                    aria-hidden="true"
                  />
                  <span className="ml-4 font-medium text-left text-white">
                    Save on average up to 43&#162; per gallon
                  </span>
                </div>
                <div className="flex items-center">
                  <CreditCardIcon
                    className="flex-shrink-0 w-8 h-8 stroke-orange-500"
                    aria-hidden="true"
                  />
                  <span className="ml-4 font-medium text-left text-white">
                    Prevent and eliminate fuel card fraud
                  </span>
                </div>
                <div className="flex items-center">
                  <GlobeAltIcon
                    className="flex-shrink-0 w-8 h-8 stroke-orange-500"
                    aria-hidden="true"
                  />
                  <span className="ml-4 font-medium text-left text-white">
                    Make vehicle related purchases everywhere Visa is accepted
                  </span>
                </div>
                <div className="flex items-center">
                  <MapPinIcon
                    className="flex-shrink-0 w-8 h-8 stroke-orange-500"
                    aria-hidden="true"
                  />
                  <span className="ml-4 font-medium text-left text-white">
                    Direct drivers to the best stations to fuel at
                  </span>
                </div>
                <div className="flex items-center">
                  <PhoneArrowDownLeftIcon
                    className="flex-shrink-0 w-8 h-8 stroke-orange-500"
                    aria-hidden="true"
                  />
                  <span className="ml-4 font-medium text-left text-white">
                    Around the clock premier support
                  </span>
                </div>
                <div className="flex items-center">
                  <LockClosedIcon
                    className="flex-shrink-0 w-8 h-8 stroke-orange-500"
                    aria-hidden="true"
                  />
                  <span className="ml-4 font-medium text-left text-white">
                    Solve driver lock-outs with the RoadFlex Driver App
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BigBanner;
